<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane :lazy="true" label="模板配置" name="1" v-if="permissionControl('TemplateConfig')">
				<templateConfig ref="pane1" pageName="TemplateConfig"></templateConfig>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import templateConfig from './templateConfig.vue'; // 评估列表

export default {
	name: 'reportSettings',
	components: {
		templateConfig
	},
	data() {
		return {
			activeName: '1',
			listQuery: {}
		};
	},

	computed: {},
	created() {},
	methods: {},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [{ name: '1', key: 'TemplateConfig' }]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`pane${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>
