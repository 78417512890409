<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			showOrder
			:activeTab="activeTab"
			custom
			showReset
		/>
		<!-- 新增 -->
		<el-dialog
			:title="dialogTitle"
			width="900px"
			top="10vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<!-- <el-row>
				<el-col :span="18"> -->
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="formItems"
				@submit="this.submitForm"
				@cancel="dialogVisible = false"
				:defaultData="formModel"
			>
				<baseTable ref="baseTable1" :dataList="dataList" :columns="rightCols" :showPagination="false" style="margin: 0 0 20px 0" />
			</formItem>
			<!-- </el-col>
				<el-col :span="6">
					<baseTable ref="baseTable1" :dataList="dataList" :columns="rightCols" :showPagination="false" style="margin: 18vh 20px" />
				</el-col>
			</el-row> -->
		</el-dialog>
	</div>
</template>

<script>
/* 评估师认定 */
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
import moment from 'moment';
export default {
	name: 'templateConfig',
	components: {
		baseTable,
		formItem
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		appraiserLevel() {
			return this.$store.state.app?.dict?.filter((item) => item.groupId === 'appraiserLevel');
		},
		otherBtns() {
			return [
				{
					icon: 'el-icon-plus',
					label: '配置',
					click: () => {
						this.formModel = {};
						this.dialogVisible = true;
						this.dialogTitle = '新增配置';
					}
				}
			].filter((v) => v);
		},
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		getKey1Opts() {
			return (type) => {
				if (type) {
					return this.getOptsById(type);
				}
				return [];
			};
		},
		getKey2Opts() {
			return (type) => {
				let nop = [];

				switch (type) {
					case 'rankName': // 管理站位
					case 'rankLevel': // 胜任
						nop = this.getOptsById('positionMatch');
						break;
					case 'fourAbilities': // 四项能力
						nop = this.getOptsById('abilityLevel');
						break;
					case 'fiveQualities': // 五项素质
						nop = this.getOptsById('abilityGrade');
						break;

					default:
						break;
				}

				return nop;
			};
		},
		getKey3Opts() {
			return (type) => {
				if (type === 'fourAbilities') {
					const arr = this.getOptsById('rankName').map((v) => ({ ...v, dictName: `初始站位：${v.dictName}` }));

					return arr;
				}
				return [];
			};
		},
		formItems() {
			return [
				{
					labelWidth: '100%',
					label: '关键区分',
					labelStyle: 'font-size: 16px;background: #f1f8fe;padding: 10px;',
					formItemClass: 'noLabelPadding'
				},
				...this.queryItem.slice(1).map((v) => {
					if (['type', 'key1'].includes(v.key)) {
						v.rules = [{ required: true, message: '请选择' }];
					}
					return { ...v, span: 8 };
				}),
				{
					label: '模板配置',
					labelWidth: '100%',
					labelStyle: 'font-size: 16px;background: #f1f8fe;padding: 10px;',
					formItemClass: 'noLabelPadding'
				},
				{
					label: ' ',
					key: 'report',
					type: 'ckeditor',
					labelWidth: '0px',
					formItemClass: 'noLabel'
				}
			];
		}
	},
	data() {
		return {
			dialogTitle: '新增',
			tableCols: [
				{
					label: '主分类',
					key: 'type',
					formatter: (v) => this.getOptsById('templateConfigPrimaryClass').find(({ dictId }) => dictId === v)?.dictName
				},
				{
					label: '关键区分1',
					key: 'key1',
					formatter: (v, { type }) => this.getKey1Opts(type).find(({ dictId }) => dictId === v)?.dictName
				},
				{
					label: '关键区分2',
					key: 'key2',
					formatter: (v, { type }) => this.getKey2Opts(type).find(({ dictId }) => dictId === v)?.dictName
				},
				{
					label: '关键区分3',
					key: 'key3',
					formatter: (v, { type }) => this.getKey3Opts(type).find(({ dictId }) => dictId === v)?.dictName
				},
				{
					label: '创建时间',
					key: 'createTime',
					formatter: (v) => moment(v).format('YYYY-MM-DD HH:mm')
				},
				{
					label: '修改时间',
					key: 'updateTime',
					formatter: (v) => moment(v).format('YYYY-MM-DD HH:mm')
				},
				{
					label: '操作',
					key: 'cz',
					width: '180px',
					fixed: 'right',
					render: (row) => (
						<div style="display:inline-flex;align-items: center;height: 22px">
							{this.permissionControlBtns(this.pageName, 'Edit') && (
								<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
									编辑
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Delete') && (
								<el-button type="text" style={'color:rgb(245, 108, 108); '} onclick={() => this.handleDel(row)}>
									删除
								</el-button>
							)}
						</div>
					)
				}
			],
			rightCols: [
				{
					label: '字段',
					key: 'prop'
				},
				{
					label: '映射',
					key: 'upon'
				}
			],
			dataList: [
				{ prop: '姓名', upon: '${userName}' },
				{ prop: '公司名称', upon: '${firmName}' },
				{ prop: '职位', upon: '${currentPosition}' }
			],
			queryItem: [
				{
					label: '时间',
					key: 'date',
					type: 'daterange',
					span: 6
				},
				{
					label: '主分类',
					key: 'type',
					type: 'select',
					groupId: 'templateConfigPrimaryClass',
					span: 6,
					change: (formModel) => {
						this.formModel = { type: formModel.type };
						['key1', 'key2', 'key2'].forEach((key) => {
							this.$set(formModel, key, '');
						});
					}
				},
				{
					label: '区分1',
					key: 'key1',
					type: 'select',
					groupId: 'rankName',
					filterOpts: (opts, { type }) => this.getKey1Opts(type),
					span: 6
				},
				{
					label: '区分2',
					key: 'key2',
					type: 'select',
					groupId: 'positionMatch',
					filterOpts: (opts, { type }) => this.getKey2Opts(type),
					span: 6
				},
				{
					label: '区分3',
					key: 'key3',
					type: 'select',
					groupId: 'fourAbilityMatches',
					filterOpts: (opts, { type }) => this.getKey3Opts(type),
					span: 6
				}
			],
			formModel: {},
			dialogVisible: false
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			if (params.request.date) {
				params.request.startTime = params.request.date[0];
				params.request.endTime = params.request.date[1];
			}
			this.$delete(params.request, 'date');

			callback(this.$axios.post('/v1/talent/reportTemplate/getTemplateList', params));
		},
		handleEdit(row, e) {
			e.stopPropagation();
			this.formModel = { ...row };
			this.dialogTitle = '编辑';
			this.dialogVisible = true;
		},
		// 删除
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios
						.post('/v1/talent/reportTemplate/deleteTemplate', {
							request: { id: row.id }
						})
						.then((res) => {
							if (res && res.code === 0) {
								this.$message.success('删除成功!');
								this.$refs.baseTable?.handleSearch();
							} else {
								// this.$message.error(res.message)
							}
						});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					this.$axios
						.post('/v1/talent/reportTemplate/saveTemplate', {
							request: { ...this.formModel }
						})
						.then((res) => {
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success(this.formModel.id ? '编辑成功' : '新增成功');
							this.dialogModel = {};
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
}
</style>
<style lang="scss">
.container {
	.noLabelPadding {
		label {
			padding: 0;
		}
	}
	.noLabel {
		label {
			width: 0;
			padding: 0;
		}
	}
}
</style>
